.login-layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    height: fit-content;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
    background-color: #ffffff;
    padding-top: 50px;
    padding-bottom: 50px;
}

.login-logo {
    width: auto;
    height: auto;
    float: left;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.login-heading {
    font-size: 20px;
    font-weight: 550;
    text-align: center;
    padding-bottom: 10px;
}
  
.login-form-forgot {
    float: right;
}

.login-form-button{
    width: 100%;
    margin-top: 25px;
    height: 40px;
    font-size: 16px;
    color: #ffffff;
}

.login-error {
    padding-top:10px;
    font-size: 12px;
    text-align: center;
}

.reset-password{
    padding: 26px 4.5%;
    border-radius: 4px;
    background-color: #ffffff;
}
  
.reset-password-text{
    width: 124px;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
  
.reset-password-heading {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}
  
.toggle-password {
    display: block;
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 11px;
    right: 15px;
    cursor: pointer;
    opacity: .9;
    margin-left: 10px;
    padding-left: 4px;
}

.toggle-password-icon{
    margin-top: 12px;
}

.reset-password-form{
    padding-left: 7%;
}

.reset-password-modal{
    width: 450px !important;
    height: 498px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
}

.reset-password-form{
    padding-left: 7%;
}
  
.reset-password-table-body {
    background-color: #ffffff;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
  
.reset-password-button{
    width: 320px;
    height: 40px;
    margin-top: 6px;
}
  
.reset-password-error{
    font-size: 12px;
    margin-top: 2px;
}

.invalidate-token-modal{
    width: 500px !important;
    height: 220px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
}

.invalidate-token-content{
    padding: 14px;
}

.invalidate-token-header{
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.invalidate-token-message{
    font-family: sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #888888;
    padding: 24px 0px 12px;
}  

.invalidate-token-button {
    height: 32px;
    margin: 20px 0px;
}

.password-label-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.forgot-password-link {
    padding-top: 16px;
    font-weight: 600;
    font-size: 14px;
    color: #888;
    line-height: 1.43;
    letter-spacing: normal;
    font-stretch: normal;
    font-style: normal;
    cursor: pointer;
    text-decoration: underline;
}