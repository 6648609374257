.refund-details-container {
    padding: 20px;
    padding-bottom: 30px !important;
}

.process-refund-heading {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.process-refund-subheading {
    padding-top: 5px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
}

.refund-details-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 420px;
    height: 60px;
    margin: 28px 0 10px;
    padding: 20px 16px;
    border-radius: 5px;
    background-color: #f8f8f8;
}

.refund-detail-header {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #333333;
}

.refund-reason-heading {
    padding-top: 20px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #333333;
}

.refund-reason {
    margin-top: 10px;
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    min-height: 75px;
    border-radius: 4px;
    border: solid 1px #b2b2b2;
    background-color: #ffffff;
    resize: none;
}

.refund-button {
    min-height: 40px;
    margin-top: 20px;
}

.refund-error{
    padding-top: 12px;
    font-size: 14px;
    line-height: 1.57;
    color: #f27382;
}

.refund-action-radio-table{
    width: 450px;
}

.refund-radio-input{
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.refund-radio-div { 
    display: flex;
    padding-top: 10px;
}

.refund-radio-text {
    padding-left: 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
}

.refund-radio-text-disabled {
    padding-left: 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
    opacity: 0.4;
}

.refund-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.refund-success-header {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-top: 16px;
}

.refund-success-subheader {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    margin-top: 8px;
}