.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    min-height: 32px;
    min-width: 30px;
    max-width: 250px;
    font-size: 14px;
    word-wrap: break-word;
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 2px;
    position: absolute;
    z-index: 20;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
  