.button{
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    padding: 0 15px;
    transition: 0.3s;
    border-width: inherit;
    height: 40px;
}

.button:hover{
    cursor: pointer;
}

.button:focus{
    outline: none;
}

.button-primary{
    background-color: #2170f4;
    color: #ffffff;
}

.button-primary:hover {
    background-color: #40a9ff ;
}
  
.button-primary:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed
}

.button-primary:focus {
    box-shadow: 0 0 4px 0 #5692f6;
    border: solid 2px #5692f6;
    background-color: #2170f4;
}
  
.button-primary:active {
    background-color: #1b60d2;
}

.button-secondary{
    background-color: #ffffff;
    border: solid 1px #2d6ced;
    color: #2170f4;
}

.button-secondary:hover{
    color: #ffffff;
    background-color: #2170f4;
    border-color: #40a9ff;
}

.button-secondary:disabled {
    background-color: #2170f4;
    cursor: not-allowed;
    color: #ffffff;
    opacity: 0.5;
}

.button-delete{
    background-color: #ff615b;
    color: #ffffff;
}

.button-delete:hover {
    opacity: 0.8;
    background-color: #ff615b;
}
  
.button-delete:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed
}

.button-delete:focus {
    box-shadow: 0 0 4px 0 #ff615b;
    border: solid 2px #ff615b;
    background-color: #ff615b;
}

.button-delete:active {
    background-color: #d74e49;
}

.button-cancel{
    background-color: #f0f0f0;
    color: #333333;
}

.button-cancel:hover {
    background-color: #f0f0f0 ;
}
  
.button-cancel:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
    color: #ffffff;
}

.button-cancel:focus {
    box-shadow: 0 0 4px 0 #f0f0f0;
    border: solid 2px #f0f0f0;
    background-color: #f0f0f0;
}

.spinner-action-continer {
    min-width: 14px;
    max-width: 14px;
}

.spinner-action {
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    margin-left: 10px;
    font-size: 10px; 
    border-radius: 50%;
    background: #2170f4;
    background: -moz-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: spin3 1.4s infinite linear;
    animation: spin3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner-action:before {
    width: 50%;
    height: 50%;
    background: #2170f4;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.spinner-action:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.reactive-check {
    margin-right: 5px !important;
    margin-left: 0px !important
}

.spinner-manage {
    margin-left: 5px;
    font-size: 10px;
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #2170f4;
    background: -moz-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: spin3 1.4s infinite linear;
    animation: spin3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner-manage:before {
    width: 50%;
    height: 50%;
    background: #2170f4;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.spinner-manage:after {
    background: #f0f0f0;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.spinner {
    font-size: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: spin3 1.4s infinite linear;
    animation: spin3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.spinner:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.spinner:after {
    background: #bdbdbd;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes spin3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes spin3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
  