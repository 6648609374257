.action-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 28px;
}

.action-button-text {
    padding-left: 10px;
}

.text-bold {
    color: #000;
    font-weight: bold;
}

.forgot-password-heading {
    margin: 0 13px 8px 14px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.forgot-password-subheading {
    margin: 8px 7px 30px 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

.password-reset-mail-header {
    color: #000;
    line-height: 1.43;
    max-width: 300px;
    font-family: sans-serif;
    font-size: 24px;
}

.password-reset-mail-text {
    color: #333;
    margin-top: 32px;
    line-height: 1.43;
    max-width: 300px;
    font-family: sans-serif;
    font-size: 14px;
}

.try-again {
    color: #2170f4;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}

.forgot-password-success-div {
    display: flex;
    flex-direction: column;
    position: relative;
}

.forgot-password-re-send {
    background-color: #ffffff;
    opacity: 0.6;
}

.forgot-password-success-spinner-container {
    display: flex;
    justify-content: center;
    align-items: top;
    position: absolute;
    width: 450px;
    height: 300px;
    padding-top: 60px;
    background-color: rgba(255,255,255,0.8);
}

.forgot-password-success-spinner {
    display: flex;
    flex-direction: column;
}

.loading-spinner-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2170f4;
}

.password-changed-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.congratulations-text {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
    padding-top: 24px;
}

.password-changed-header {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #333333;
    padding-top: 8px;
}

.action-button-spinner-container {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-action-button {
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    margin-left: 10px;
    font-size: 10px; 
    border-radius: 50%;
    background: #2170f4;
    background: -moz-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: spin3 1.4s infinite linear;
    animation: spin3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner-action-button:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.spinner-action-button:after {
    background: #2170f4;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.spinner-reset-password {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    margin-left: 10px;
    font-size: 10px; 
    border-radius: 50%;
    background: #2170f4;
    background: -moz-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: spin3 1.4s infinite linear;
    animation: spin3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner-reset-password:before {
    width: 50%;
    height: 50%;
    background: #2170f4;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.spinner-reset-password:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}