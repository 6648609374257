.order-list-item {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 2.5px;
    padding-top: 16px;
    text-align: center;
}
  
.order-list-item-first {
    text-align: left !important;
    padding-left: 20px;
}

.order-list-header-item {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #888888;
    text-align: center;
    padding-bottom: 2.5px;
}

.no-border-order-list-row {
    border-bottom: none !important;
}
  
.orders-response{
    margin-left: 1%;
    margin-bottom: 1%;
}

.domain-order-for {
    width: auto;
    height: auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #888888;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.domain-name {
    width: auto;
    height: auto;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.order-list-box {
    width: 100%;
    min-width: 800px;
    border-radius: 4px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 4px;
    overflow: visible;
}

.order-list-row {
    display: flex;
    border-bottom: solid 1px #DEDEDE;
    width: 100%;
    min-height: 12px;
    padding-bottom: 14px;
    padding-top: 12px;
}
  
.order-list-row-header {
    padding-bottom: 11px;
}

.orders-table-button{
    min-width: 74px;
    height: 32px;
    margin-left: 20px;
    margin-right: 20px;
}

.order-column-header{
    font-size: 12px !important;
    color: #888888 !important;
    line-height: 1.33 !important;
}

.order-column-partner{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 2.5px;
    text-align: start;
    min-width: 140px;
    max-width: 140px;
    margin-left: 15px;
}

.order-column-source{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 2.5px;
    text-align: start;
    min-width: 340px;
    max-width: 340px;
    margin-left: 20px;
}

.order-column-accounts{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 2.5px;
    text-align: center;
    min-width: 100px;
    max-width: 100px;
    margin-left: 70px;
}

.order-column-creation-date{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 2.5px;
    text-align: start;
    min-width: 120px;
    max-width: 140px;
    margin-left: 20px;
}

.order-column-status{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 2.5px;
    text-align: start;
    min-width: 100px;
    max-width: 120px;
    margin-left: 20px;
}

.order-column-domain{
    font-weight: 600;
}

.suspended-text {
    color: #f27383;
    margin-left: 8px;
    font-size: 16px;
}

.order-list-suspension-container {
    display: flex;
    margin-left: 8px;
}

.order-list-suspension-separator {
    width: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #bdbdbd;
}

.order-list-suspension-icon {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-left: 10px;
}