/* Common Scrollbar Styles */
.scrollbar {
    overflow: auto;
    position: relative;
}

.scrollbar::-webkit-scrollbar {
    width: 8px; /* For vertical scrollbar */
    height: 8px; /* For horizontal scrollbar */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Lighter color for the scrollbar thumb */
    border-radius: 4px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #bbb; /* Slightly darker color on hover */
}

.scrollbar::-webkit-scrollbar-track {
    background: #e6e6e6; /* Light background color for the scrollbar track */
}
