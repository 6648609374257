.t-range-picker > .ant-calendar-picker-input {
  border: solid 1px #bdbdbd;
  width: 300px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.65);
}

.t-range-picker > .ant-calendar-picker-input,
.t-range-picker > .ant-calendar-picker-input > input {
  cursor: pointer;
}

div.ant-calendar-range .ant-calendar-input-wrap {
  height: 40px;
}

.t-range-picker > .ant-calendar-picker-input .ant-calendar-range-picker-separator {
  margin: 0 10px 0 0;
}

.t-calender-popover a[class$="year-btn"] {
  display: none;
}