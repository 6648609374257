.swap-content-heading {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
}

.swap-content-body {
    font-size: 14px;
    padding-top: 10px;
    line-height: 20px;
    color: #888888;
}

.swap-icon-popover-div {
    padding: 16px;
}

.swap-icon-content-div {
    max-width: 250px;
}

.swap-icon:hover {
    cursor: pointer;
}