.logo {
    max-width: 220px;
    height: 64px;
    float: left;
    display: flex;
    align-items: center;
}
  
.titan-logo {
    width: 160px;
    height: 29.1px;
    object-fit: contain;
    margin-left: 23px;
    margin-bottom: 20px;
}
  
.flock-logo-text {
    width: 100%;
    height: 24px;
    font-size: 18px;
    object-fit: contain;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 2px;
    margin-bottom: 20px;
    margin-left: 16px;
    border-right: solid 1px #39395b
}

.state-logo{
    margin-bottom: 2px;
    margin-right: 3px;
}

.success-logo{
    width: 16px;
    height: 16px;
    object-fit: contain;
}
  
.error-logo{
    width: 16px;
    height: 16px;
    object-fit: contain;
}
  
  