.progress-div {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
}
.progress-bar {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 100px;
}

.progress{
    position: relative;
    background-color: #1890ff;
    border-radius: 100px;
    -webkit-transition: all .4s cubic-bezier(.08,.82,.17,1) 0s;
    transition: all .4s cubic-bezier(.08,.82,.17,1) 0s;
}