.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    border-radius: 4px;
    box-shadow: 0 10px 14px -1px rgba(51, 51, 51, 0.2);
    z-index: 1;
}

.dropdown:hover {
    cursor: pointer;
}

.dropdown-arrow{
    position: absolute;
    display: block;
    right: 18px;
    top: 56px;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.dropdown-item{
    margin: 6px 0px;
}
.dropdown-next-item{
    padding: 6px 0px;
    border-top: solid 1px #dedede ;
}

.dropdown-menu-item {
    text-align: left;
    padding-left: 24px;
    padding-bottom: 8px;
    padding-top: 8px ;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
}

.dropdown-menu-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.dropdown-menu-item:active {
    background-color: #f0f0f0
}

/* .dropdown-content {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.26);
    border: solid 1px #dedede;
    background-color: #ffffff;
    min-width: 220px;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: auto;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
} */
