.form {
    width: 320px;
}

.form-header{
    text-align: -webkit-center;
}

.form-item{
    margin-bottom: 24px;
}

.form-sub-heading{
    width: 319px;
    height: 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 24px;
    color: #333333;
}
  
.input-label{
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    line-height: 1.43;
    letter-spacing: normal;
    font-stretch: normal;
    font-style: normal;
}

.reset-label{
    margin-bottom: 25px;
    width: 141px;
    height: 20px;
}
  
.input{
    border-radius: 4px;
    background-color: #ffffff;
}

.input:focus{
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2);
    border-color: #40a9ff;
    border-right-width: 1px!important;
}
  
.input:hover{
    border-color: #40a9ff;
    border-right-width: 1px!important;
}

.input-box{
    width: 100%;
    border: solid 1px #bdbdbd;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
}

.input-div{
    display: inline-flex;
    width: 320px;
    height: 40px;
}

.input-error{
    border-color: #fe581e!important;
}

.no-border-input{
    border: none !important;
    outline: none;
    width: inherit;
    border-color: transparent !important;
}

.no-border-input:focus{
    border: none !important;
    outline: none;
    border-color: transparent !important;
}

.no-border-input:hover{
    border: none !important;
    outline: none;
    border-color: transparent !important;
}

.error{  
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #fe581e;  
}

.t-select {
    display: inline-block;
    padding: 8px 30px 8px 12px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../assets/select-arrow.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: calc(100% - 10px);
    background-color: #FFFFFF;
    border: 1px solid #b2b2b2;
    box-sizing: border-box;
    border-radius: 4px;
}

.t-select:focus,
.t-select:active {
    outline: none;
}