.dashboard-form {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    font-weight: 600;
    padding-top: 15px;
}
  
.dashboard-error {
    margin-top: 2px;
    font-size: 10px;
    margin-left:10px;
    min-height: 18px;
}

.dashboard-label{
    height: 20px;
    margin-top: 10px;
    display: flex;
}

.dashboard-input{
    width: 250px;
    height: 40px;
    border: solid 1px #bdbdbd;
    padding-left: 10px;
    margin-left: 10px;
    display: flex;
}

.dashboard-button{
  height: 40px;
  margin-left: 10px;
  margin-bottom: 18px;
}