.recover-accounts-container {
    display: flex;
    flex-direction: column;
    min-width: 700px;
}

.recover-accounts-heading-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.recover-accounts-heading {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.recover-accounts-heading-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #333333;
}

.recover-accounts-navigate-back {
    width: 20px;
    height: 20px;
    margin-top: 20px;
    margin-left: -40px;
}

.recover-accounts-navigate-back:hover {
    cursor: pointer;
}

.source-order-container {
    width: 100%;
    min-width: 800px;
    border-radius: 4px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
}

.source-order-table-header {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #888888;
}

.source-order-column-data {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 2.5px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.column-partner {
    text-align: start;
    min-width: 140px;
    max-width: 140px;
    margin-left: 15px;
}

.column-source {
    text-align: start;
    min-width: 320px;
    max-width: 320px;
    margin-left: 15px;
    overflow-wrap: break-word;
}

.column-accounts {
    text-align: center;
    min-width: 100px;
    max-width: 100px;
    margin-left: 40px;
}

.column-plan {
    text-align: center;
    min-width: 100px;
    max-width: 100px;
    margin-left: 20px;
}

.column-customer-email {
    text-align: start;
    min-width: 220px;
    max-width: 220px;
    margin-left: 20px;
    overflow-wrap: break-word
}

.source-order-row {
    display: flex;
    width: 100%;
    align-items: center;
}

.accounts-list-header {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    margin-top: 15px;
}

.account-details-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 40px;
}

.account-details-table-container {
    width: 60%;
    min-width: 500px;
    border-radius: 4px 4px 0px 0px ;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
}

.recoverable-accounts-container {
    padding-top: 20px;
}

.accounts-details-header {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    margin-top: 15px;
}

.account-details-table {
    width: 100%;
    margin-top: 15px;
}

.account-details-header-row {
    display: flex;
    border-bottom: solid 1px #DEDEDE;
    width: 100%;
    min-height: 12px;
    padding-bottom: 10px;
    padding-top: 12px;
}

.account-details-row {
    display: flex;
    border-bottom: solid 1px #DEDEDE;
    width: 100%;
    min-height: 12px;
    padding-bottom: 7px;
    padding-top: 7px;
    align-items: center
}

.account-details-column-data {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
}

.account-details-column-data-disabled {
    font-size: 16px;
    line-height: 1.5;
    color: #333333;
    opacity: 0.6;
}

.account-detail-column1 {
    text-align: start;
    min-width: 50%;
    max-width: 50%;
    margin-left: 10px;
    overflow-wrap: break-word
}

.account-detail-column2 {
    text-align: center;
    min-width: 40%;
    max-width: 40%;
    margin-left: 20px;
}

.recover-button-container {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: start;
    background-color: #ffffff;
    border-radius: 0px 0px 4px 4px;
}

.recover-button {
    min-height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.recover-button:disabled {
    background-color: #2d6ced !important;
    opacity: 0.5;
    cursor: not-allowed
}

.account-checkbox {
    padding-left: 4px;
    vertical-align: sub;
    height: 16px;
    width: 16px;
    visibility: visible;
}

.account-checkbox:disabled {
    cursor: not-allowed
}

.recover-email-account-alert{
    font-size: 12px;
    line-height: 1.17;
    color: #333333;
}

.recover-email-account-alert-container {
    display: flex;
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede;
}

.recover-email-account-alert-text {
    padding-left: 8px;
}

