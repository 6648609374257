.add-user-modal {
    min-width: 540px;
    height: fit-content;
    width: 540px;
    min-height: 230px;
    border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 10px 14px -1px rgba(51, 51, 51, 0.2);
    border: solid 1px #dedede;
}

.add-user-modal-container {
    padding: 20px 30px 30px
}

.add-user-modal-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    color: #333333;
}

.input-box-heading {
    padding-top: 20px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 5px;
}

.email-input-box {
    width: 100%;
    height: 100px;
    padding-left: 10px;
    border-radius: 4px;
    border: 1px solid #b2b2b2;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 10px;
    color: #333
}

.email-input-box::placeholder {
    padding-top: 10px;
    color: #888888;
    font-size: 14px;
    font-weight: normal
}

.email-input-box:focus {
    outline: none 
}

.dropdown-container {
    position: relative;
}

.dropdown-header {
    width: 100%;
    height: 40px;
    border: solid 1px #bdbdbd;
    padding: 6px 12px;
    display: flex;
    border-radius: 4px;
    background-color: #ffffff;
    font-weight: normal;
}

.dropdown-list-container {
    padding: 16px 0px;
    border-radius: 4px;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.26);
    border: solid 1px #cdcccc;
    background-color: #ffffff;
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 200px;
    overflow: scroll;
}

.role-dropdown-menu-item {
    text-align: left;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
    font-weight: normal;
}

.role-dropdown-menu-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.partner-dropdown-menu-item {
    text-align: left;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 15px;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
    font-weight: normal;
}

.partner-dropdown-menu-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.dropdown-header-title {
    font-size: 14px;
    letter-spacing: normal;
    color: #333333;
    width: 100%;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.partner-dropdown-placeholder {
    font-size: 14px;
    letter-spacing: normal;
    color: #333;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    opacity: 0.5
}

.center-text {
    display: flex;
    align-items: center;
}

.user-role-text {
    padding-left: 5px;
    color: #333;
    font-weight: normal
}

.create-user-button-container {
    display: flex;
    margin-top: 20px;
}

.create-user-button {
    min-height: 40px;
}

.create-user-button:disabled {
    background-color: #2d6ced !important;
    opacity: 0.5;
    cursor: not-allowed
}

.create-user-error{
    padding-top: 12px;
    font-size: 14px;
    line-height: 1.57;
    color: #f27382;
    font-weight: normal;
}

.make-admin-checkbox-container {
    padding-top: 10px;
    font-weight: normal;
}

.chip-input-container {
    border: solid 1px #b2b2b2b2;
    border-radius: 4px;
    margin-top: 5px;
    padding: 10px;
}

.chip-input-root {
    width: 100%;
    min-height: 100px;
    height: fit-content;
}

.chip-input {
    padding: 0 0 8px !important;
}
.partner-list-checkbox {
    padding-bottom: 10px;
    padding-top: 10px;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
    font-weight: normal;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
}

.partner-list-checkbox:hover {
    background-color: #f0f0f0;
}

.partner-list-item-text {
    color: #333333;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding-left: 14px;
    width: 100%;
}

.partner-list-checkbox > input {
    height: 16px;
    width: 18px;
    appearance: none;
    border-radius: 2px;
    border: solid 1px #dedede;
    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;
    margin-left: 28px;
  }

.partner-list-checkbox > input:checked {
    border: solid 1px #2170F4;
    background-color: #2170F4;
}

.partner-list-checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 30px;
    font-weight: 700;
}

.make-admin-checkbox {
    vertical-align: sub;
    height: 16px;
    width: 16px;
    margin-right: 6px;
}
