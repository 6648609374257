.user-list-container {
    display: flex;
    width: 800px;
    margin-top: 100px;
    flex-direction: column;
}

.user-list-manage-accounts {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.user-list-table-container {
    padding: 0px 20px 10px 20px;
    margin-top: 30px;
    border-radius: 4px;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 60px;
}

.user-list-table {
    width: 100%;
    table-layout: auto;
}

.user-list-table th {
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    color: #888888;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    background: white;
}

.user-list-table tr {
    background: #ffffff;
    border-bottom: solid 1px #dedede;
    vertical-align: initial;
    padding-top: 15px;
    width: 100%
}

.user-list-table tr:last-child {
    border-bottom: none
}

.user-list-table td{ 
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    overflow-wrap: break-word;
    vertical-align: middle
}

.user-list-email {
    max-width: 40%;
}

.user-list-center-text {
    text-align: center;
}

.user-list-table-button {
    margin-left: 20px;
    margin-right: 20px;
    height: 30px;
}

.user-list-admin {
    font-size: 10px;
    border: solid 1px #c0c0c0;
    margin-left: 10px;
    padding: 2px 5px 2px 5px;
    border-radius: 3px;
    font-weight: 800;
}

.user-list-email-container {
    display: flex;
    align-items: center;
}

.user-list-email-text {
    max-width: 100%;
    overflow-wrap: break-word;
}

.add-user-button {
    padding: 3px 30px;
    height: 32px;
}

.add-user-button:focus {
    border: none !important;
}

.add-user-button:active {
    border: none !important;
}

.add-user-button-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-user-spinner {
    margin-left: 10px;
    font-size: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #f0f0f0;
    background: -moz-linear-gradient(left, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: spin3 1.4s infinite linear;
    animation: spin3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.add-user-spinner:before {
    width: 50%;
    height: 50%;
    background: #f0f0f0;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.add-user-spinner:after {
    background: #2170f4;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.user-list-table-button-container {
    text-align: right;
    position: relative;
}

.overflow-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.overflow-button {
    cursor: pointer;
    transform: rotate(90deg);
}

.user-option-dropdown-container {
    position: absolute;
    width: 200px;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.26);
    border: solid 1px #dedede;
    right: 0;
    background: white;
    margin-right: 18px;
    z-index:100;
    padding: 10px 0px
}