.order-info-box {
    margin-top:10px;
    width: 100%;
    height: fit-content;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: fit-content;
    box-shadow: 0 1px 8px 0 rgba(217, 217, 217, 0.5);
    background-color: #ffffff;
    border-bottom: solid 1px #f0f0f0;
}

.order-info-box .order-details-item {
    border-bottom: solid 1px #f0f0f0;
}

.order-details-container {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 15px;
}

.order-info-single-item {
    width: 100%;
    height: fit-content;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 2px;
    padding-top: 2px;
    margin-top: 10px;
    min-width: fit-content;
    box-shadow: 0 1px 8px 0 rgba(217, 217, 217, 0.5);
    background-color: #ffffff;
}

.order-details-box {
    background-color: #f3f4f8;
    height: 100%;
    display: flex;
    justify-content: center;
    border-spacing: 10px;
    padding-left: 24px;
    margin-bottom: 1%;
    min-width: fit-content;
}

.order-details-item {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
}

.order-details-item-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
    padding-top: 5px;
    padding-bottom: 5px;
}

.order-details-overflow-item {
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}
  
.order-details-box.ant-layout {
    display: table-row;
    vertical-align: text-bottom;
    height: 10%;
    width: 100%;
    background-color: #f0f2f5; 
}
  
.order-details-header-text{
    width: 206px;
    height: 24px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
    margin: 10px;
}

.order-details-column {
    display: table-cell;
    width: 50%;
    min-width: 620px;
    padding-right: 20px;
}

.order-details-column-email {
    margin-left: 5%;
}
  
.order-details-column-header {
    position: sticky;
    top: 0;
    background-color: #f3f4f8;
    height: 30px;
    display: table;
    width: 100%; 
    table-layout: fixed; 
    border-spacing: 0px;
    z-index: 10;
    box-shadow: 0px 0px 0px 6px #f3f4f8;
}
  
.order-details-column-header-column {
    display: flex;
    height: 10%;
    align-self: baseline;
}
  
.order-details-column-header-text {
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
}

/* .admin-panel-button{
    height: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 400 !important;
} */
  
.no-mail-div{
    display: flex;
    margin-top: 2%;
}
  
.no-mail-logo{
    margin-right: 1%;
}
  
.no-mail-content{
    margin-top: 1.2px;
    width: auto;
    height: auto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
  }

.order-details-button.button-secondary{
    min-height: 32px;
    margin-right: 10px;
    font-weight: 500 !important;
    background-color: #f0f0f0 ;
    border: solid 2px #f0f0f0;
}

.order-details-button.button-secondary:hover{
    background-color: #dedede;
    border: solid 2px #dedede;
    color: #2170f4;
}

.order-details-button.button-secondary:active{
    background-color: #697178;
    color: #ffffff;
}

.order-details-button.button-secondary:focus{
    box-shadow: 0 0 4px 0 #5692f6;
    border: solid 2px #5692f6;
    background-color: #f0f0f0;
    color: #2170f4;
}

.order-details-button.button-secondary:disabled{
    background-color: #bdbdbd;
    color: #ffffff;
}

.delete-order-modal{
    width: 500px !important;
    height: fit-content;
    min-height: 230px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
    /* background-color: #ffffff; */
}

.customer-order-for-suspension-modal {
    width: 600px !important;
    height: fit-content;
    min-height: 230px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
}

.delete-order-content{
    padding: 14px;
}

.delete-order-content .t-select {
    width: 140px;
    margin-top: 8px;
}

.delete-order-content .t-separator {
    background: #DEDEDE;
    height: 1px;
    margin: 20px 0 15px;
    display: block;
}

.delete-order-content .t-form-field {
    margin-bottom: 0;
}

.delete-order-success{
    position: relative;
    display: grid;
    justify-items: center;
    margin: auto;
    top: 3%;
    /* left: 28%; */
    /* padding: 30px 120px 30px 135px; */
}

.delete-orders-header{
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
}

.order-modal-list{
    padding-left: 10px;
    overflow-y: auto;
    max-height: 200px;
}

.delete-orders-message{
  font-family: sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #888888;
  padding: 24px 0px 12px;
} 

.delete-orders-note {
    padding-top: 40px;
}

.delete-buttons{
    margin: 20px 0px;
    display: flex;
}

.delete-order-button{
    height: 32px;
    margin-right: 16px;
}

.delete-order-error{
    padding-top: 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #ff615b;
}

.deleting-order-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deleting{
    padding-left: 5px;
}

.configure-trial-success-header {
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    padding-top: 40px;
}

.configure-trial-success-message {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #888888;
  padding-top: 8px;
}

.configure-trial-success-button-div {
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    width:100%
}

.configure-trial-success-button {
    width: 100%;
    height: 32px;
}

.configure-trial-success-modal {
    width: 360px !important;
    height: 323px;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.configure-trial-success{
    position: relative;
    display: grid;
    justify-items: center;
    margin: auto;
    top: 12%;
}

.manage-dropdown-content {
    position: absolute;
    background-color: #ffffff;
    width: 215px;
    border-radius: 4px;
    padding: 5px 0px;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.26);
    border: solid 1px #dedede;
    z-index: 1;
}

.manage-dropdown-content-on-top {
    bottom: 32px !important;
}
 
.manage-button-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manage-button-header {
    padding-left: 24px;
    padding-top: 8px;
    font-size: 11px;
    color: #888888;;
}

.manage-button-header-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
    margin-bottom: 6px;
}

.manage-button-divider {
    flex-grow: 1;
    height: 1px;
    margin-top: 10px;
    margin-left: 5px;
    justify-content: center;
    border-bottom: solid 1px #f0f0f0
}

.admin-panel-button {
    margin-right: 0px !important
}

.order-detail-caret-down {
    display: inline-block;
    width:0px;
    height:0px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-bottom: 0 dotted;
    border-left: 4px solid transparent;
    margin-left:10px;
}

.order-details-dropdown-collapse {
    margin-left:10px;
}

.suspension-container,
.t-modal-content {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

.suspension-heading,
.form-heading {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
}

.form-note {
    margin: 10px 0 30px;
    font-size: 14px;
    font-weight: normal;
    color: #333333;
}

.suspension-subheading,
.form-subheading {
    padding-top: 5px;
    color: #333333;
}

.form-subheading {
    margin-bottom: 30px;
}

.suspension-input-heading {
    padding-top: 20px;
    color: #333333;
    font-weight: 600;
}

.suspension-dropdown-container {
    padding-top: 10px;
    position: relative;
    padding-bottom: 10px;
}

.suspension-dropdown-header {
    width: 100%;
    height: 40px;
    border: solid 1px #bdbdbd;
    padding: 6px 12px;
    display: flex;
    border-radius: 4px;
    background-color: #ffffff
}

.suspension-dropdown-header-title-placeholder {
    opacity: 0.5;
    font-size: 14px;
    letter-spacing: normal;
    color: #333333;
    width: 100%
}

.suspension-dropdown-header-title {
    font-size: 14px;
    letter-spacing: normal;
    color: #333333;
    width: 100%
}


.suspension-dropdown-list-container {
    padding-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.26);
    border: solid 1px #cdcccc;
    background-color: #ffffff;
    position: absolute;
    z-index: 1;
    width: 100%
}

.suspension-dropdown-menu-item {
    text-align: left;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 15px;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
}

.suspension-dropdown-menu-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.suspension-dropdown-menu-item:active {
    background-color: #f0f0f0
}

.suspension-note-container {
    padding-top: 20px;
}

.suspension-note-extra {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #888888;
}

.suspension-note {
    margin-top: 10px;
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    min-height: 75px;
    border-radius: 4px;
    border: solid 1px #b2b2b2;
    background-color: #ffffff;
    resize: none;
}

.suspension-note:focus { 
    outline: none !important;
    color: #333333;
}

.suspension-note::placeholder {
    opacity: 0.5;
    font-size: 14px;
    line-height: 1.57;
    color: #333333;
}

.suspension-footer {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 12px;
    line-height: 1.5;
    color: #888888;
}

.suspension-footer ul {
    padding: 0;
    list-style-position: inside;
}

.suspension-button,
.t-button-container > .button-primary {
    min-height: 40px;
}

.suspension-button:disabled,
.t-button-container > .button-primary:disabled {
    background-color: #2d6ced !important;
    opacity: 0.5;
    cursor: not-allowed
}

.modal-cancel-button,
.t-button-container > .button-secondary {
    margin-left: 20px;
    background-color: #ffffff;
    border: solid 1px #2d6ced;
    color: #2170f4;
}

.modal-cancel-button:hover,
.t-button-container > .button-secondary:hover {
    border-color: blue;
}

.t-form-field {
    margin-bottom: 30px;
}

.t-form-field > label,
input[type="checkbox"] + label {
    color: #333333;
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
}

.suspension-custom-reason,
.t-form-field > input,
.t-form-field > select {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    border-radius: 4px;
    border: solid 1px #b2b2b2;
    background-color: #ffffff;
    color: #333333;
}

.suspension-custom-reason:focus,
.t-form-field > input:focus {
    outline: none;
}

.suspension-custom-reason::placeholder,
.t-form-field > input::placeholder {
    opacity: 0.5;
    color: #333333;
}

.server-error {
    font-size: 14px;
    line-height: 1.57;
    color: #f27382;
    margin: 5px 0 10px;
    display: block;
}

.suspension-error,
.t-form-field > span {
    padding-top: 12px;
    font-size: 14px;
    line-height: 1.57;
    color: #f27382;
}

.modal-button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-button-success-icon {
    margin-right: 10px;
}

.suspension-button-container,
.t-button-container {
    display: flex;
}

.modal-spinner {
    margin-right: 10px;
    font-size: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #f0f0f0;
    background: -moz-linear-gradient(left, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #f0f0f0 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: spin3 1.4s infinite linear;
    animation: spin3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.modal-spinner:before {
    width: 50%;
    height: 50%;
    background: #f0f0f0;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.modal-spinner:after {
    background: #2170f4;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.unsuspension-container {
    padding: 20px;
    padding-bottom: 30px !important;
}

.unsuspension-list-heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: normal;
    color: #333333;
    padding-top: 20px;
    padding-bottom: 11px;
}

.unsuspension-table{
    max-height: 450px;
    overflow-y: auto;
}

.unsuspension-table-header-user {
    width: 40%;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #333333;
    padding-left: 10px;
}

.unsuspension-table-header-reason {
    width: 60%;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #333333;
    padding: 10px;
}

.unsuspension-table-header-row {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
}

.unsuspension-table-data-row-enabled {
    height: 60px;
    max-height: 70px;
    vertical-align: initial;
    background-color: #ffffff;
}

.unsuspension-table-data-row-disabled {
    height: 60px;
    max-height: 70px;
    vertical-align: initial;
    background-color: #f4f4f4;
}

.unsuspension-table-cell {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.unsuspension-table-cell-email {
    max-width: 200px;
    overflow-wrap: break-word;
}

.unsuspension-table-data-heading{
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #333333;
    font-size: 14px;
}

.unsuspension-table-data-subheading{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #888888;
}

.unsuspension-note {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    min-height: 75px;
    border-radius: 4px;
    border: solid 1px #b2b2b2;
    background-color: #ffffff;
    resize: none;
}

.unsuspension-note:focus { 
    outline: none !important;
    color: #333333;
}

.unsuspension-note::placeholder {
    opacity: 0.5;
    font-size: 14px;
    line-height: 1.57;
    color: #333333;
}

.unsuspension-footer-note{
    margin-top: 10px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #888888;
}

.unsuspension-checkbox-header{
    margin-left: 10px;
    margin-top: 12px;
    height: 16px;
    width: 16px;
}

.unsuspension-checkbox{
    padding-left: 10px;
    vertical-align: sub;
    height: 16px;
    width: 16px;
}

.checkbox-hidden {
    padding-left: 10px;
    vertical-align: sub;
    height: 16px;
    width: 16px;
    visibility: hidden;
}

.checkbox-shown {
    padding-left: 10px;
    vertical-align: sub;
    height: 16px;
    width: 16px;
    visibility: visible;
}

.suspension-modal{
    width: 500px !important;
    max-width: 500px !important;
    height: fit-content;
    min-height: 230px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
}

.unsuspension-modal{
    width: 600px !important;
    max-width: 600px !important;
    height: fit-content;
    min-height: 230px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
}

.alert-modal {
    width: 500px !important;
    height: fit-content;
    min-height: 200px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
}

.alert-modal-heading {
    margin-left: 16px;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #333333
}

.alert-modal-subheading {
    margin-left: 16px;
    margin-top: 4px;
    font-size: 14px;    
    color: #333333
}

.alert-modal-description {
    margin-left: 16px;
    margin-top: 20px;
    font-size: 14px;    
    color: #333333
}

.alert-modal-error {
    margin-left: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #f27382
}

.alert-modal-button-container {
    margin-left: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
}

.reactivate-domain-description {
    margin-left: 16px;
    margin-top: 20px;
    font-size: 14px;    
    color: #888888 
}

.reactivate-domain-not-allowed {
    margin-bottom: 20px
}

.alert-modal-close-button {
    float: right;
    cursor: pointer;
}

.alert-modal-icon {
    margin-right: 10px;
}

.retry-button {
    min-height: 40px;
}

.order-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-status-text {
    padding-left: 5px;
}

.order-status-popup-icon {
    color: grey;
    height: 15px;
    width: 15px;
    margin-left: 10px;
    margin-top: 5px;
}

.customer-email-suspension-subheading {
    font-size: 12px !important;
    line-height: 1.17;
    color: #888888 !important;
    padding-top: 2px;
}

.customer-email-suspension-container {
    text-align: right;
}

.customer-email-suspension-status-text {
    padding-left: 5px;
}

.order-status-popup-icon-customer-email {
    margin-top: 3px !important;
}

.account-info-buttons-container{
    padding-top: 20px;
    padding-bottom: 20px;
}

.domain-info-buttons-container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.alert-container-unsuspension {
    display: flex;
    border-radius: 5px;
    border: solid 1px #eae0ac;
    background-color: #fffddf;
    font-size: 12px;
    margin-bottom: 20px;
}

.alert-container-suspension {
    display: flex;
    border-radius: 5px;
    border: solid 1px #eae0ac;
    background-color: #fffddf;
    font-size: 12px;
    margin-top: 20px;
}

.alert-container-icon {
    height: 14px;
    width: 13px;
    margin-top: 13px;
    margin-left: 10px;
}

.alert-container-text {
    margin: 10px;
}

.forward-keep-copy-div {
    color: #888888;
    font-size: 12px;
    margin-top: -12px;
    text-align: right;
    padding-bottom: 15px;
}

.forward-not-keep-copy-text {
    color: #F27383;
}

.order-details-customer-name-text {
    color: #888888;
}

.order-details-customer-name-separator {
    background: #888888;
    margin-left: 5px;
    margin-right: 5px;
    height: 10px;
    width: 1px;
}

.order-details-customer-container {
    display: flex;
    align-items: center;
}

.order-details-customer-email-text {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tooltiptextCustomer {
    max-width: 200px !important;
    padding: 10px !important;
}

.checkbox-field {
    margin: 15px 0 5px;
}

div.t-data-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    color: #2170f4;
    z-index: 10;
}

.inaccuracy-points {
    background: #F5F4FF;
    padding: 20px 20px 5px;
    margin: 20px auto 4px;
}

.inaccuracy-points > label {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.inaccuracy-points ul {
    padding: 0;
    margin: 10px auto 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}

.inaccuracy-points li {
    flex: 1 0 50%; /* Two columns */
    display: flex;
    margin-bottom: 8px;
    align-items: center;
}

.inaccuracy-points li label {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline-block;
    margin-left: 6px;
    cursor: pointer;
}

.inaccuracy-points li input[type="checkbox"] {
    width: 16px;
    height: 16px;
}