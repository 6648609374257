.container {
  min-width: 840px;
}

.header {
  display: flex;
  align-items: baseline;
}

.loginHistory {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  margin-top: 20px;
}

.filterIcon {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 4px;

  &.active {
    >path {
      fill: #2170f4;
    }
  }
}

.noLogs {
  text-align: center;
}

.tableWrapper {
  max-height: calc(100vh - 250px);
}

.clientName {
  max-width: 200px !important;
}