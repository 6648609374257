.action-history-container{
    display: flex;
    flex-direction: column;
    min-width: 700px;
}

.action-history-heading-container {
    display: flex;
    height: 60px;
    align-items: center;
    background-color: #f3f4f8;
    position: sticky;
    top: 0px;
}

.action-history-heading {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: #333333;
    margin-left: 20px;
    max-width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.action-history-list-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    margin: 0 5px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 8px 0 rgba(217, 217, 217, 0.5);
}

.action-history-navigate-back {
    width: 20px;
    height: 20px;
    margin-top: 3px;
    margin-left: -40px;
}

.pagination-details-container .action-history-heading-container {
    background-color: #fff;
    z-index: 100;
}

.pagination-details-container .disabled-arrow {
    opacity: .4;
    cursor: not-allowed;
}

.pagination-details-container .action-history-heading {
    margin-left: 0;
}

.pagination-details-container .action-history-navigate-back {
    left: -60px;
    margin-left: 0;
    position: absolute;
}

.action-history-navigate-back:hover {
    cursor: pointer;
}

.action-history-list {
    max-width: 850px;
}

.action-history-list td{ 
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    overflow-wrap: break-word;
    vertical-align: middle
}

.action-history-list th {
    height: 40px;
    position: sticky;
    top: 60px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 12px;
    color: #888888;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    background: white;
    border-bottom: solid 1px #dedede;
}

.action-history-list tr {
    background: #ffffff;
    border-bottom: solid 1px #dedede;
    vertical-align: initial;
    padding-top: 15px;
}

.action-history-list tr:last-child {
    border-bottom: none
}

.no-action-history-list {
    text-align: center;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    font-size: 14px !important;
}

.action-history-button-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-history-time {
    font-size: 12px !important;
    width: 110px !important;
    padding-left: 10px
}

.action-history-info {
    min-width: 300px;
}

.action-history-time-heading {
    width: 110px !important;
    padding-left: 10px
}

.action-detail-link {
    padding-right: 4px;
    color: #2170f4 !important;
    text-decoration: underline;
}

.action-detail-link:hover{
    cursor: pointer !important;
}

.action-history-details th {
    height: 45px !important;
    vertical-align: middle;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    top: 60px;
    color: #888888;
}

.action-history-details tr{
    height: 30px;
    border-top: solid 1px #dedede;
}

.action-history-details-key {
    padding-right: 15px;
    min-width: 200px;
    max-width: 200px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #888888;
    overflow-wrap: break-word;
    vertical-align: middle
}

.action-history-details-value {
    min-width: 500px;
    max-width: 500px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
    overflow-wrap: break-word;
    vertical-align: middle
}

.action-link-div{
    display: flex;
    justify-content: start;
    align-items: center;
    padding-right: 30px !important;
}

.action-history-alert {
    font-size: 11px !important;
    color: #888888 !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.action-history-alert-text {
    padding-left: 10px
}