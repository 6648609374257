.main-content {
    background-color: #f3f4f8;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%
}
  
.no-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
  
.no-content-text {
    width: 100%;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #888888;
}

.no-content-text-line-one{
    width: 100%;
    border: 0px;
    margin: 0px
}

.navigate-back{
    position: sticky;
    top: 0;
    width: 20px;
    height: 20px;
    float: left;
    min-width: 20px;
    min-height: 20px;
    margin-right: 20px;
}

.navigate-back:hover{
    cursor: pointer;
}
  