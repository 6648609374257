.modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    align-items: center;
}


.modal-content {
    z-index: 1000;
    padding: 10px;
    border: 1px solid #888;
    width: 25%;
    background: #ffffff;
    max-height: 90vh;
    overflow-y: auto;
}


.close-modal {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  
