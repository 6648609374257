.navbar{
    box-shadow: 0 2px 7px 0 rgba(136, 136, 136, 0.5);
    background-color: #24273d;
    height: 45px;
}

.navbar-item{
    text-align: center;
    color: white;
    box-sizing: border-box;
    height: 45px;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 45px;
    font-feature-settings: "tnum","tnum";
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    outline: none;
    background-color: rgb(36, 39, 61);
    zoom: 1;
    margin-right: 16px;
}

.navbar-item:hover{
    cursor: pointer;
}

.navbar-item-active{
    border-bottom: solid 4px #2170f4;
    font-weight: bold;
}

.navbar-item:active{
    border-bottom: solid 4px #2170f4;
}

.navbar-item-width{
    min-width: 85px;
}

.navbar-options.navbar-item {
    float: right;
    border-bottom: none;
    margin-right: 5px;
    padding-right: 24px;
    z-index: 99;
}

.navbar-options:hover{
    cursor: default !important;
}

.navbar-options-text {
    padding-right: 24px;
    height: 19px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.options-dropdown.dropdown-content{
    background-color: #f9f9f9;
    width: 215px;
}
