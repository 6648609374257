.customer-suspension-container {
    padding: 10px 20px 20px 20px;
}

.customer-suspension-order-summary {
    padding-top: 24px;
    display: flex;
}

.customer-suspension-order-summary-heading {
    font-size: 12px;
    color: #888888
}

.customer-suspension-order-summary-value {
    padding-left: 5px;
    padding-right: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #333333;
}

.customer-suspension-order-table {
    margin-top: 20px;
    max-height: 400px;
    overflow: auto;
    width: 100%;
}

.customer-suspension-order-table table {
    width: 100%;
    table-layout: fixed;
}

.customer-suspension-order-table th {
    font-size: 11px;
    font-weight: normal;
    color: #888888;
    padding: 10px 20px 10px 10px;;
    border-bottom: solid 1px #f0f0f0;
    border-top: solid 1px #f0f0f0;
}

.customer-suspension-order-table tr {
    border-bottom: solid 1px #f0f0f0;
    border-top: solid 1px #f0f0f0;
}

.customer-suspension-order-table td {
    padding: 16px 20px 16px 10px;
    word-wrap: break-word;
}

.customer-order-domain {
    font-size: 14px;
    font-weight: 600;
}

.customer-order-table-center-text {
    text-align: center;
}

.customer-suspension-button-container {
    display: flex; 
    margin-top: 32px;
}

.customer-suspension-order-summary-unsuspension {
    padding-top: 24px;
    display: flex;
    padding-bottom: 10px;
}

.customer-order-table-domain{
    width: calc(100% - 220px)
}

.customer-order-table-account {
    width: 90px;
}

.customer-order-table-status {
    width: 130px;
}

.customer-order-table-plan {
    width: 100px;
}