.add-user-success-modal {
    height: fit-content;
    width: 540px;
    min-height: 230px;
    border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 10px 14px -1px rgba(51, 51, 51, 0.2);
    border: solid 1px #dedede;
}

.add-user-success-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px 30px;
}

.invites-sent-modal-image {
    width: 164px;
    height: 74px;
    align-self: center;
    margin-top: 20px;
}

.invites-sent-text {
    font-size: 20px;
    line-height: 23px;
    margin-top: 25px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}

.invites-not-sent-text {
    font-style: normal;
    font-weight: normal;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    text-align: center;
}