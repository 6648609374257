.toast.Toastify__toast--info{
    width: 491px;
    height: 56px;
    border-radius: 4px;
    background-color: #333333;
}
  
.toast-body.Toastify__toast-body{
    margin: auto 19px;
}
  
.toast-button{
    margin: 14px 12px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    align-self: flex-start;
}