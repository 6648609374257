.suspension-popover-empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    min-height: 150px;
}

.suspension-popover-heading {
    font-size: 14px;
    color: #888888;
    display: flex;
    justify-content: space-between
}

.suspension-popover-unsuspend-option {
    font-size: 12px;
    font-weight: 600;
    color: #2170f4;
    cursor: pointer;
    padding-left: 20px;
}

.suspension-popover-table{
    max-height: 500px;
    overflow-y: auto;
    padding-top: 16px;
}

.suspension-popover-table tr {
    border-bottom: 1px solid #dedede
}

.suspension-popover-table tr:last-child{
    border-bottom: none
}


.suspension-popover-table-header {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    color: #888888;
    width: 50%;
    font-weight: 400;
}

.suspension-popover-table-data-heading {
    line-height: 1.57;
    letter-spacing: normal;
    color: #333333;
    font-size: 12px;
}

.suspension-popover-table-cell {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 0px;
}

.suspension-popover-table-cell-email {
    max-width: 200px;
    overflow-wrap: break-word
}

.suspension-popover-retry-button {
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 30px;
}

.suspension-popover-container{
    max-width: 800px;
}

.suspension-table-container {
    max-width: 100%;
    position: relative;
}
  
.table {
    width: 100%;
    border-collapse: collapse;
}
  
.table th,
.table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
}
  
.table th {
    background-color: #f4f4f4;
    white-space: nowrap;
    font-size: 14px;
    color: #666;
}
  
.bullet-list {
    padding-left: 16px;
    margin: 0;
}

.cell,
.cell-large {
    word-wrap: break-word;
    white-space: pre-wrap; 
}

.suspension-table-body {
    color: #000;
}