.dns-status{
    margin-top: 10px;
    /* margin-bottom: 10px; */
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: #888888;
}
  
.dns-header{
    display: flex;
    justify-content: space-between;
} 

.dns-popup-header{
    display: flex;
    align-items: center;
    flex-direction: row;
}
  
.dns-popup-item {
    width: auto;
    margin-top: 5%;
    line-height: 1.5;
}
 
.dns-response{
    margin-left: 5%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
}

.dns-ns-response{
    margin-left: 10%;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
}
.success{
    margin-left: 5%;
    width: 26px;
    height: 22px;
    color: #333333;
}
  
.dns-error{
    color: #f27383;
}

.no-response{
    margin-left: 5%;
    color: #333333;
}

.dns-layout{
    margin: 0px 20px;
    width: 322px;
    padding-bottom: 21px;
    position: relative;
}
  
.dns-hover{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;  
    margin: -25px -20px;
    padding: 25px 20px;
}

.dns-hover-icon {
    margin-right: 20px;
}

.dns-retry-link {
    padding-top: 5px;
}

.reponse-content{
    line-height: 1.5;
    margin-left: 10%;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #888888;
}

.dns-menu-ns-header {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #333333;
}