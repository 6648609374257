.confirmation-modal {
    width: 500px !important;
    height: fit-content;
    min-height: 170px;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #c3c3c3;
}

.confirmation-modal-heading {
    margin-left: 16px;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #333333
}

.confirmation-modal-description {
    margin-left: 16px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #888888;
}

.confirmation-modal-button-div {
    display: flex;
    flex-direction: row;
}

.confirmation-modal-button-positive {
    min-height: 40px;
    margin-left: 16px;
    margin-top: 20px;
}

.confirmation-modal-button-negative {
    background-color: #ffffff;
    color: #2170f4;
    border: solid 2px #5692f6;
    min-height: 40px;
    margin-left: 16px;
    margin-top: 20px;
}

.confirmation-modal-button-negative:hover {
    background-color: #ffffff;
}

.confirmation-modal-close-button {
    float: right;
    cursor: pointer;
}

.confirmation-modal-icon{
    margin-right: 10px
}