.loader {
    position: absolute;
    margin: auto;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; 
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } 
  
  .log-loader {
    position: absolute;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 36px;
    height: 36px;
    left: 50%;
    -webkit-animation: spin 1.5s linear infinite; 
    animation: spin 1.5s linear infinite;
    margin-top: 30px;
  }

  .button-loader {
    margin: auto;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; 
    animation: spin 2s linear infinite;
  }
