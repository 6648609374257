.recovery-widget-container {
    position: absolute;
    width: 250px;
    max-height: 70%;
    overflow: auto;
    bottom: 5px;
    margin-left: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 8px 0 rgba(217, 217, 217, 0.5);
    border: solid 1px #e3e0e0;
    background-color: #ffffff;
    z-index: 100;
}

.recovery-widget-heading {
    border-radius: 4px;
    border: solid 1px #dedede;
    background-color: #24273d;
    color: #ffffff;
    padding: 15px 15px 15px 12px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recovery-widget-subheading {
    background-color: rgba(153, 153, 153, 0.1);
    padding: 6px 10px 6px 12px;
    font-size: 10px;
    color: #666666;
}

.recovery-widget-table {
    max-height: 400px;
    overflow-y: scroll;
    width: 100%
}

.recovery-widget-table tr {
    border-bottom: solid 1px #efefef;
}

.recovery-widget-table-domain {
    font-size: 13px;
    font-weight: 600;
    color: #2170f4;
    padding-top: 20px !important
}

.recovery-widget-table td {
   padding: 10px;
   width: 250px;
}

.recovery-widget-table-item-container {
    display: flex;
}

.recovery-widget-table-item {
  font-size: 12px;
  color: #333333;
  margin-left: 10px;
}

.recovery-widget-icon {
    margin-top: 2px;
}

.recovery-widget-item-error {
    font-size: 10px;
    color: #f27382;
    margin-left: 10px;
}

.recovery-widget-spinner {
    margin-top: 2px;
    font-size: 10px;
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #2170f4;
    background: -moz-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #2170f4 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: spin3 1.4s infinite linear;
    animation: spin3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.recovery-widget-spinner:before {
    width: 50%;
    height: 50%;
    background: #2170f4;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.recovery-widget-spinner:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.recovery-widget-heading-center-container {
    display: flex;
    align-items: center;
}

.recovery-widget-heading-text {
    margin-left: 10px;
}

.recovery-widget-header-icon {
    cursor: pointer
}

.recovery-widget-close-icon {
    cursor: pointer;
    margin-left: 10px;
}