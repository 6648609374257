
.log-details-container,
.pagination-details-container {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: #ffffff
}

div.pagination-spinner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logs-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: #ffffff;
    border-bottom: 1px solid #dedede
}

.pagination-details {
    position: relative;
}

.pagination-details-container .page-div {
    align-self: flex-end;
}

.table-container {
    max-height: 400px;
    overflow: auto;
    padding: 0px 20px 20px;
    background: #ffffff;
    position: relative;
}

.log-details,
.pagination-details {
    width: 100%;
    border-radius: 4px;
    background-color: #ffffff;
}
.log-details td,
.pagination-details td {
    padding-right: 15px;
    max-width: 400px;
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    overflow-wrap: break-word;
}

.log-details th,
.pagination-details th {
    height: 40px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    background: white;
    position: sticky;
    top: 0;
}

.pagination-details th {
    top: 60px;
}

.log-details tr,
.pagination-details tr {
    vertical-align: initial;
    padding-top: 15px;
}

/*  Define the background color for all the EVEN background rows  */
.log-details tr:nth-child(odd),
.pagination-details tr:nth-child(odd) {
    background: #f0f0f0;
}

.log-details tr:first-child ,
.pagination-details tr:first-child  {
    background: #ffffff;
    border-bottom: solid 1px #dedede;
}

.log-details tr:nth-child(2) td,
.pagination-details tr:nth-child(2) td {
    padding-top:15px
}

.log-filter {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
    padding-bottom: 15px;
}

.log-filter-item {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
}

.log-filter-heading {
    padding-left: 5px;
    padding-right: 10px 
}

.log-filter-checkbox {
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.log-filter-checkbox > span {
    color: #34495E;
    padding: 0.5rem 0.25rem;
}

.log-filter-checkbox > input {
    height: 18px;
    width: 18px;
    appearance: none;
    border-radius: 3px;
    border: solid 1px #888888;
    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;
  }

.log-filter-checkbox > input:checked {
    border: solid 1px #1b60d2;
    background-color: #ffffff;
}

.log-filter-checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #1b60d2;
    position: absolute;
    left: 0.2rem;
    font-weight: 700;
}

.required-asterisk {
    color: #fe581e;
}

.log-filter-radio,
.log-filter-radio > input {
    cursor: pointer;
    position: relative;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.log-details-time {
    font-size: 12px !important;
    padding-left: 10px
}

.log-details-time-heading {
    padding-left: 10px
}

.log-details-status {
    padding-left: 15px;
    min-width: 150px;
}

.log-details-status-text {
    padding-left: 3px;
}

.log-details-info {
    padding-right: 15px !important;
    max-width: 500px !important;
    min-width: 300px !important;
}

.log-details-email {
    max-width: 200px !important;
    min-width: 200px !important;
}

.logs-form {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #fff;
    font-weight: 600;
    padding-top: 20px;
    z-index: 2;
}

.logs-form-ip {
    padding-top: 0px !important;
}

.logs-label{
    width: 200px;
    height: 20px;
    margin-left:10px;
    display: flex;
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: normal;
    color: #333333;
}

.logs-input{
    width: 250px;
    height: 40px;
    border: solid 1px #bdbdbd;
    padding: 6px 12px;
    margin-left:10px;
    margin-right:10px;
    display: flex;
}

.logs-button{
    height: 40px;
    margin-left: 10px;
    margin-top: 22px;
    border: solid 2px #2170f4;
}

.copy-button{
    padding-right: 10px !important;
    vertical-align: middle;
}

.copy-div{
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
}

.copied-text{
    font-size: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    opacity: 0.7;
    background-color: #000000;
    border-radius: 4px;
}

.copied-text-visible{
    visibility: visible;
}

.copied-text-hidden{
    visibility: hidden;
}

.page-div{
    max-height: 30px;
    float: right;
    display: flex;
    align-content: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
}

.page-text{
    height: 20px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
    margin-left: 30px;
    margin-right: 10px;
    margin-top: 3px;
}

.prev-page{
    width: 30px;
    height: 30px;
    border-right: solid 1px #bdbdbd;
}

.next-page{
    width: 30px;
    height: 30px;
}

.page-buttons{
    width: 60px;
    height: 30px;
    border-radius: 4px;
    border: solid 1px #bdbdbd;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
}

.records-div{
    display: flex;
    flex-direction: column-reverse;
}

.records-dd-header{
    width: 60px;
    height: 30px;
    border: solid 1px #bdbdbd;
    padding-top: 2px;
    margin-left:5px;
    margin-right:10px;
    display: flex;
    border-radius: 4px;
    background-color: #ffffff;
}

.records-dd-header-title{
    width: 32px;
    padding-left: 10px;
    font-style: normal;
    font-weight: normal;
}

.records-dd-list-container{
    width: 56px;
    margin-left: 7px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 7px 0 rgba(136, 136, 136, 0.5);
    z-index: 3;
}

.records-dd-menu-item{
    width: 56px;
    text-align: left;
    padding-left: 12px;
    padding-bottom: 8px;
    padding-top: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #333333;
}

.records-dd-menu-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.records-dd-menu-item:active {
    background-color: #f0f0f0
}

.scroll-to-top-button {
    bottom: 50px;
    left: 100%;
    cursor: pointer;
    margin-right: -55px;
    position: sticky;
}

.logs-change-button {
    border-radius: 5px;
    background-color: #ddeaff;
    font-size: 16px;
    font-weight: 600;
    color: #2170f4;
    padding: 10px;
    height: 40px;
    margin-top: 22px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 148px;
    cursor: pointer;
}

.logs-change-button-selected {
    box-shadow: 0 2px 8px 0 #d9d9d9 !important;
    background-color: #ffffff !important;
}

.logs-change-button-disabled {
    cursor: not-allowed !important;
}

.logs-change-button-arrow {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2170f4;
    margin-left: 10px;
}

.logs-change-dropdown-menu {
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 #d9d9d9;
    background-color: #ffffff;
    margin-right: 20px !important;
    margin-top: 0px !important;
}

.log-change-item {
    margin: 0px !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.log-change-menu-item {
    padding-left: 10px !important;
}

.logs-filter-options-container {
    display: flex;
    justify-content: center;
}

.form-field-optional-label {
    margin-left: 4px;
}


/* Redis cache logs css */
.email-list-container {
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    margin: 20px;
    min-width: 800px;
}

.cached-mailbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cached-mailbox-email {
    color: #000000;
}

.email-not-found {
    margin: 40px;
    color: #000000;
}

.success-removed-from-cache {
    color: #28a745;
    font-weight: 600;
    text-align: center;
}

.log-table-wrapper {
    max-width: 1100px;
}

.ip-logs-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.ip-log-details-header {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 20px;
    margin-top: 32px;
    border-radius: 4px;
    color: #000000;
}

.table-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
    color: #000000;
}

.status-display {
    display: flex;
    align-items: center;
}

.status-container {
    display: flex;
}

.download-logs {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 20px 0 10px auto;
    border: none;
    background: transparent;
    color: #2170F4;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    white-space: nowrap;
}

.loading.download-logs {
    cursor: not-allowed;
    opacity: 0.5;
}

.logs-container {
    min-width: 600px
}